.mids .header {
  width: 100%;
  background-color: #F9F9F9;
  height: 70px;
  margin-top: 60px;
}

.mids .input-column {
  width: 18%;
  min-width: 200px;
  margin: 10px;
}

.mids .table-column table > tbody > tr > td:last-child,
.mids .table-column table > thead > tr > th:last-child {
  text-align: end;
}

.mids .mids .panel-content {
  overflow: auto;
}

.mids .panel-content tbody td {
  border: 0;
}

.mids .add-deposit-button {
  background-color: #223679;
  color: #FFFFFF;
  margin-left: 10px;
  font-size: 12px;
}

.mids .add-withdrawal-button {
  background-color: #223679;
  color: #FFFFFF;
  font-size: 12px;
}

.mids .add-deposit-button.active {
  color: #FFFFFF;
}

.mids .add-withdrawal-button.active {
  color: #FFFFFF;
}

.mids .add-deposit-button:hover {
  background-color: #0066CC;
  color: #FFFFFF;
}

.mids .add-withdrawal-button:hover {
  background-color: #0066CC;
  color: #FFFFFF;
}

.mids .mids-table {
  width: 100%;
  overflow: auto;
  margin-top: 15px;
  position: relative;
}

.mids .mids-table thead tr {
  background-color: #223679;
  width: 100%;
  color: #FFFFFF;
}

.mids .mids-table th {
  padding: 8px;
}

.mids .mids-table th:nth-child(1) {
  border-radius: 6px 0 0 6px;
}

.mids .mids-table th:nth-last-child(1) {
  border-radius: 0 6px 6px 0;
}

.mids .mids-table td {
  word-wrap: break-word;
  vertical-align: middle;
}

.mids .mids-table .open-modal-btn,
.mids .mids-table .open-modal-btn:active,
.mids .mids-table .open-modal-btn:focus {
  background: transparent;
  border: none;
  padding: 3px 10px;
  text-decoration: underline;
  outline: none !important;
  box-shadow: none;
}

.mids .mids-table .open-modal-btn:hover {
  box-shadow: inset 0 0 6px rgb(0 0 0 / 12.5%);
}

.mids .mids-table .view-mid {
  height: 35px;
  width: 70px;
  background-color: #3BE13B;
  border-radius: 5px;
  display: block;
  color: #FFFFFF;
  margin: auto;
  padding: 7px;
}

.mids .mids-table .view-mid:hover {
  background-color: #223679;
}

.mids .mids-table .edit-mid {
  height: 35px;
  width: 70px;
  background-color: #FFFFFF;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
  padding: 7px;
  color: #000000;
  display: block;
  margin: auto;
}

.mids .mids-table .edit-mid:hover {
  background-color: #223679;
  color: #FFFFFF;
}

.mids .error-field {
  border: 2px solid #FF0000;
  border-radius: 7px;
}

.mids .error-message {
  font-size: 14px;
  color: red;
  margin-top: 2px;
}

.mids .mids-radio:checked,
.mids-radio:not(:checked) {
  position: absolute;
  left: -9999px;
}

.mids .mids-radio:checked + label,
.mids-radio:not(:checked) + label {
  position: relative;
  padding-left: 40px;
  padding-top: 5px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666666;
}

.mids .mids-radio:checked + label::before,
.mids-radio:not(:checked) + label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 35px;
  height: 35px;
  border: 1px solid #CCCCCC;
  border-radius: 100%;
  background: #FFFFFF;
}

.mids .mids-radio:checked + label::after,
.mids-radio:not(:checked) + label::after {
  content: '';
  width: 21px;
  height: 21px;
  background: #223679;
  position: absolute;
  top: 7px;
  left: 7px;
  border-radius: 100%;
  transition: all 0.2s ease;
}

.mids .mids-radio:not(:checked) + label::after {
  opacity: 0;
  transform: scale(0);
}

.mids .mids-radio:checked + label::after {
  opacity: 1;
  transform: scale(1);
}

.mids .holiday-table-wrapper {
  width: 100%;
  overflow: auto;
  position: relative;
}

.mids .holiday-table {
  min-width: 500px;
}

.mids .holiday-table tr th {
  border: 0;
}

.mids .holiday-table .td-toggler {
  width: 80px;
  border: 0;
  vertical-align: middle;
}

.mids .holiday-table .td-element {
  min-width: 150px;
  position: relative;
  vertical-align: middle;
}

.mids .holiday-table .td-disabled {
  position: relative;
  background-color: #F1F1F1;
}

.mids .holiday-table .td-error {
  border: 2px solid #FF0000;
}

.mids .holiday-table .table-input {
  width: 100%;
  border: 0;
  outline: 0;
  text-align: right;
}

.mids .holiday-table .td-actions {
  min-width: 100px;
  width: 100px;
}

.mids .holiday-table .add .plus {
  position: absolute;
  top: 1px;
  left: 10px;
}

.mids .holiday-table .add,
.mids .holiday-table .delete {
  width: 30px;
  margin: 6px;
  outline: 0;
  padding: 0;
}

.mids .holiday-table .add {
  position: relative;
  border-radius: 50%;
  box-shadow: 0;
  border: 0;
  font-size: 18px;
  font-weight: bold;
  height: 30px;
  background-color: #223679;
  color: #FFFFFF;
}

.mids .holiday-table .delete {
  border-radius: 50%;
  box-shadow: 0;
  border: 0;
  font-size: 12px;
  font-weight: bold;
  height: 30px;
  background-color: #CCCCCC;
  color: #FFFFFF;
}

.mids .mids-calendar-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
}

.mids .day-item {
  margin: 5px;
}

.mids .mids-datetime-container .form-control[readonly] {
  background-color: #FFFFFF;
}

.mids .Select.is-disabled > .Select-control {
  background-color: #EEEEEE;
}

.mids .burger-container .burger-bar1,
.mids .burger-container .burger-bar2,
.mids .burger-container .burger-bar3 {
  width: 30px;
  height: 3px;
  background-color: #223679;
  margin: 6px 0;
  transition: 0.4s;
}

.mids .burger-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 200px;
  top: 120px;
  right: 15px;
  border: 2px solid #CCCCCC;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.mids .burger-content .link-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.mids .burger-content .link-item {
  display: block;
  width: 100%;
  margin: 5px 0;
  padding: 5px 5px 5px 15px;
  color: #223679;
}

.mids .burger-content .link-item:hover {
  color: #FFFFFF;
  background-color: #223679;
}

.mids .change .burger-bar1 {
  transform: rotate(-45deg) translate(-9px, 3px);
}

.mids .change .burger-bar2 {
  opacity: 0;
}

.mids .change .burger-bar3 {
  transform: rotate(45deg) translate(-8px, -5px);
}

@media screen and (width >= 1200px) and (width <= 1599px) {
  .mids .mids-table .table-component {
    width: calc(100vw - 121px);
  }
}

@media screen and (width >= 992px) and (width <= 1199px) {
  .mids .mids-table .table-component {
    width: calc(100vw - 171px);
  }
}

@media screen and (width >= 768px) and (width <= 991px) {
  .mids .mids-table .table-component {
    width: calc(100vw - 121px);
  }
}

@media screen and (width >= 651px) and (width <= 767px) {
  .mids .mids-table .table-component {
    width: 100vw;
  }
}

@media screen and (width >= 651px) and (width <= 1599px) {
  .mids .mids-table .table tr td:first-child,
  .mids .mids-table .table tr th:first-child {
    position: sticky;
    left: 0;
    z-index: 40;
    background-color: inherit;
  }
}

@media screen and (width <= 500px) {
  .mids .day-schedule {
    width: 100% !important;
    flex-direction: column !important;
  }

  .mids .day-item {
    text-align: left;
    width: 100%;
  }

  .mids .panel {
    max-width: calc(var(--vw, 1vw) * 100 + 10px) !important;
  }

  .mids .mids-table .table-component td,
  .mids .mids-table .table-component th {
    min-width: 150px;
  }

  .mids .mids-table .table {
    width: auto;
  }

  .mids .mids-table .table thead > tr {
    position: sticky;
    left: 0;
    z-index: 50;
    display: block;
  }

  .mids .mids-table .table tbody {
    display: block;
  }

  .mids .mids-table .table tr td:first-child,
  .mids .mids-table .table tr th:first-child {
    position: sticky;
    left: 0;
    z-index: 40;
    background-color: inherit;
  }
}

@media screen and (width <= 650px) {
  .mids .add-deposit-button,
  .mids .add-withdrawal-button {
    display: none;
  }

  .mids .input-column {
    flex-grow: 1;
  }

  .mids .holiday-table-wrapper {
    width: calc(var(--vw, 1vw) * 100 - 20px);
  }

  .mids .holiday-table td:not(:last-child),
  .mids .holiday-table th:not(:last-child) {
    min-width: 210px;
  }

  .mids .holiday-table td:last-child,
  .mids .holiday-table th:last-child {
    min-width: 120px;
  }

  .mids .holiday-table thead > tr {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 50;
    display: block;
    background-color: #FFFFFF;
  }

  .mids .holiday-table tbody {
    display: block;
  }

  .mids .holiday-table tr td:first-child,
  .mids .holiday-table tr th:first-child {
    position: sticky;
    left: -8px;
    z-index: 40;
    background-color: inherit;
  }

  .mids .mids-table .table-component td,
  .mids .mids-table .table-component th {
    width: 150px;
  }

  .mids .mids-table {
    width: 100vw;
  }
}

@media screen and (width >= 650px) {
  .mids .burger-container {
    display: none;
  }
}
